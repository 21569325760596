// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-components-home-how-it-works-page-js": () => import("/opt/build/repo/src/pagesComponents/home/HowItWorksPage.js" /* webpackChunkName: "component---src-pages-components-home-how-it-works-page-js" */),
  "component---src-pages-components-home-about-page-js": () => import("/opt/build/repo/src/pagesComponents/home/AboutPage.js" /* webpackChunkName: "component---src-pages-components-home-about-page-js" */),
  "component---src-pages-components-style-guide-style-guide-js": () => import("/opt/build/repo/src/pagesComponents/styleGuide/StyleGuide.js" /* webpackChunkName: "component---src-pages-components-style-guide-style-guide-js" */),
  "component---src-pages-components-home-be-tilliste-page-js": () => import("/opt/build/repo/src/pagesComponents/home/BeTillistePage.js" /* webpackChunkName: "component---src-pages-components-home-be-tilliste-page-js" */),
  "component---src-pages-components-home-legal-terms-js": () => import("/opt/build/repo/src/pagesComponents/home/LegalTerms.js" /* webpackChunkName: "component---src-pages-components-home-legal-terms-js" */),
  "component---src-pages-components-my-account-login-js": () => import("/opt/build/repo/src/pagesComponents/myAccount/Login.js" /* webpackChunkName: "component---src-pages-components-my-account-login-js" */),
  "component---src-pages-components-my-account-forgotten-password-request-js": () => import("/opt/build/repo/src/pagesComponents/myAccount/ForgottenPasswordRequest.js" /* webpackChunkName: "component---src-pages-components-my-account-forgotten-password-request-js" */),
  "component---src-pages-components-my-account-reset-password-js": () => import("/opt/build/repo/src/pagesComponents/myAccount/ResetPassword.js" /* webpackChunkName: "component---src-pages-components-my-account-reset-password-js" */),
  "component---src-pages-components-my-account-my-orders-js": () => import("/opt/build/repo/src/pagesComponents/myAccount/MyOrders.js" /* webpackChunkName: "component---src-pages-components-my-account-my-orders-js" */),
  "component---src-pages-components-home-insurance-js": () => import("/opt/build/repo/src/pagesComponents/home/Insurance.js" /* webpackChunkName: "component---src-pages-components-home-insurance-js" */),
  "component---src-pages-components-home-insurance-tilliste-js": () => import("/opt/build/repo/src/pagesComponents/home/InsuranceTilliste.js" /* webpackChunkName: "component---src-pages-components-home-insurance-tilliste-js" */),
  "component---src-pages-components-home-press-page-js": () => import("/opt/build/repo/src/pagesComponents/home/PressPage.js" /* webpackChunkName: "component---src-pages-components-home-press-page-js" */),
  "component---src-pages-components-home-partners-page-js": () => import("/opt/build/repo/src/pagesComponents/home/PartnersPage.js" /* webpackChunkName: "component---src-pages-components-home-partners-page-js" */),
  "component---src-pages-components-home-why-become-partner-js": () => import("/opt/build/repo/src/pagesComponents/home/WhyBecomePartner.js" /* webpackChunkName: "component---src-pages-components-home-why-become-partner-js" */),
  "component---src-pages-components-home-ecosystem-page-js": () => import("/opt/build/repo/src/pagesComponents/home/EcosystemPage.js" /* webpackChunkName: "component---src-pages-components-home-ecosystem-page-js" */),
  "component---src-pages-components-home-faq-page-js": () => import("/opt/build/repo/src/pagesComponents/home/FAQPage.js" /* webpackChunkName: "component---src-pages-components-home-faq-page-js" */),
  "component---src-pages-components-home-invite-js": () => import("/opt/build/repo/src/pagesComponents/home/Invite.js" /* webpackChunkName: "component---src-pages-components-home-invite-js" */),
  "component---src-pages-components-home-pricings-page-js": () => import("/opt/build/repo/src/pagesComponents/home/PricingsPage.js" /* webpackChunkName: "component---src-pages-components-home-pricings-page-js" */),
  "component---src-pages-components-home-feedbacks-page-js": () => import("/opt/build/repo/src/pagesComponents/home/FeedbacksPage.js" /* webpackChunkName: "component---src-pages-components-home-feedbacks-page-js" */),
  "component---src-pages-components-home-job-offer-page-js": () => import("/opt/build/repo/src/pagesComponents/home/JobOfferPage.js" /* webpackChunkName: "component---src-pages-components-home-job-offer-page-js" */),
  "component---src-pages-components-order-order-page-js": () => import("/opt/build/repo/src/pagesComponents/order/OrderPage.js" /* webpackChunkName: "component---src-pages-components-order-order-page-js" */),
  "component---src-pages-components-order-step-1-clothes-js": () => import("/opt/build/repo/src/pagesComponents/order/Step1Clothes.js" /* webpackChunkName: "component---src-pages-components-order-step-1-clothes-js" */),
  "component---src-pages-components-order-estimation-variant-step-2-alterations-js": () => import("/opt/build/repo/src/pagesComponents/order/estimationVariant/Step2Alterations.js" /* webpackChunkName: "component---src-pages-components-order-estimation-variant-step-2-alterations-js" */),
  "component---src-pages-components-order-estimation-variant-step-3-fabrics-js": () => import("/opt/build/repo/src/pagesComponents/order/estimationVariant/Step3Fabrics.js" /* webpackChunkName: "component---src-pages-components-order-estimation-variant-step-3-fabrics-js" */),
  "component---src-pages-components-order-estimation-variant-step-4-rdv-js": () => import("/opt/build/repo/src/pagesComponents/order/estimationVariant/Step4Rdv.js" /* webpackChunkName: "component---src-pages-components-order-estimation-variant-step-4-rdv-js" */),
  "component---src-pages-components-order-estimation-variant-step-5-login-js": () => import("/opt/build/repo/src/pagesComponents/order/estimationVariant/Step5Login.js" /* webpackChunkName: "component---src-pages-components-order-estimation-variant-step-5-login-js" */),
  "component---src-pages-components-order-estimation-variant-step-6-infos-js": () => import("/opt/build/repo/src/pagesComponents/order/estimationVariant/Step6Infos.js" /* webpackChunkName: "component---src-pages-components-order-estimation-variant-step-6-infos-js" */),
  "component---src-pages-components-order-containers-success-container-js": () => import("/opt/build/repo/src/pagesComponents/order/containers/SuccessContainer.js" /* webpackChunkName: "component---src-pages-components-order-containers-success-container-js" */),
  "component---src-pages-components-order-fast-variant-step-2-rdv-js": () => import("/opt/build/repo/src/pagesComponents/order/fastVariant/Step2Rdv.js" /* webpackChunkName: "component---src-pages-components-order-fast-variant-step-2-rdv-js" */),
  "component---src-pages-components-order-fast-variant-step-3-fabrics-js": () => import("/opt/build/repo/src/pagesComponents/order/fastVariant/Step3Fabrics.js" /* webpackChunkName: "component---src-pages-components-order-fast-variant-step-3-fabrics-js" */),
  "component---src-pages-components-order-fast-variant-step-4-login-js": () => import("/opt/build/repo/src/pagesComponents/order/fastVariant/Step4Login.js" /* webpackChunkName: "component---src-pages-components-order-fast-variant-step-4-login-js" */),
  "component---src-pages-components-order-fast-variant-step-5-infos-js": () => import("/opt/build/repo/src/pagesComponents/order/fastVariant/Step5Infos.js" /* webpackChunkName: "component---src-pages-components-order-fast-variant-step-5-infos-js" */),
  "component---src-pages-components-order-containers-payment-container-js": () => import("/opt/build/repo/src/pagesComponents/order/containers/PaymentContainer.js" /* webpackChunkName: "component---src-pages-components-order-containers-payment-container-js" */),
  "component---src-pages-components-order-containers-payment-success-container-js": () => import("/opt/build/repo/src/pagesComponents/order/containers/PaymentSuccessContainer.js" /* webpackChunkName: "component---src-pages-components-order-containers-payment-success-container-js" */),
  "component---src-templates-la-rubrique-post-template-container-js": () => import("/opt/build/repo/src/templates/LaRubriquePostTemplateContainer.js" /* webpackChunkName: "component---src-templates-la-rubrique-post-template-container-js" */),
  "component---src-templates-la-rubrique-list-template-js": () => import("/opt/build/repo/src/templates/LaRubriqueListTemplate.js" /* webpackChunkName: "component---src-templates-la-rubrique-list-template-js" */),
  "component---src-templates-our-artisans-template-js": () => import("/opt/build/repo/src/templates/OurArtisansTemplate.js" /* webpackChunkName: "component---src-templates-our-artisans-template-js" */),
  "component---src-templates-landing-page-template-js": () => import("/opt/build/repo/src/templates/LandingPageTemplate.js" /* webpackChunkName: "component---src-templates-landing-page-template-js" */),
  "component---src-templates-our-artisans-list-template-js": () => import("/opt/build/repo/src/templates/OurArtisansListTemplate.js" /* webpackChunkName: "component---src-templates-our-artisans-list-template-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

exports.data = () => import("/opt/build/repo/.cache/data.json")

